/* General App styles */
.App {
    text-align: center;
}

.App-logo {
    height: 20vmin;
    pointer-events: none;
    max-width: 100%; /* Ensures the logo doesn't overflow */
}

.App-header {
    min-height: 25vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 1rem;
    text-align: center;
}

.App-content {
    padding: 2rem;
}

/* Responsive styling */
@media (max-width: 768px) {
    .App-header {
        min-height: 20vh;
        font-size: calc(8px + 2vmin);
    }

    .App-logo {
        height: 15vmin;
    }

    .App-content {
        padding: 1rem;
    }
}

@media (max-width: 480px) {
    .App-header {
        min-height: 15vh;
        font-size: calc(8px + 1.5vmin);
    }

    .App-logo {
        height: 10vmin;
    }

    .App-content {
        padding: 0.5rem;
    }
}

.product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Popup Overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure popup is above other elements */
}

/* Popup Container */
.popup {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    text-align: center;
    position: relative;
}

/* Popup Close Button */
.popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

/* Popup Actions */
.popup-actions {
    margin-top: 20px;
}

.popup-actions button {
    margin: 0 10px;
}
